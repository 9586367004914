import { ThemeOptions } from "@material-ui/core";
import { overrides } from "./Theme";
import ThemeShadows from "./ThemeShadows";

export default {
  palette: {
    type: "dark",
    primary: {
      main: "#9FC448",
    },
    secondary: {
      main: "#ef7f1f",
    },
    background: {
      default: "#2a2d28",
    },
  },
  shape: {
    borderRadius: 18,
  },
  spacing: 20,
  shadows: ThemeShadows,
  overrides: {
    ...overrides,
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#171817",
      },
    },
  },
  typography: {
    fontFamily: [
      "ui-rounded",
      '"SF Pro Rounded"',
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "4em",
    },
    h2: {
      fontSize: "3.5em",
    },
    h3: {
      fontSize: "2.8em",
    },
    h4: {
      fontSize: "2.3em",
    },
    h5: {
      fontSize: "1.5em",
    },
    h6: {
      fontSize: "1.2em",
    },
  },
} as ThemeOptions;
