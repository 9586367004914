import { decomposeColor, recomposeColor } from "@material-ui/core";

export default function colorByProgress(progress: number): string {
  if (progress >= 0.5) {
    return interpolate("#ffcd16", "#89c23c", progress);
  }
  return interpolate("#d94b72", "#ffe52a", progress);
}

export function darkerColorByProgress(progress: number): string {
  if (progress >= 0.5) {
    return interpolate("#ff5722", "#009688", progress);
  }
  return interpolate("#720329", "#cd7e0b", progress);
}

export function interpolate(
  from: string,
  to: string,
  fraction: number
): string {
  const fC = decomposeColor(from);
  const tC = decomposeColor(to);

  const red =
    fC.values[0] + (tC.values[0] - fC.values[0]) * Math.min(fraction, 1);
  const green =
    fC.values[1] + (tC.values[1] - fC.values[1]) * Math.min(fraction, 1);
  const blue =
    fC.values[2] + (tC.values[2] - fC.values[2]) * Math.min(fraction, 1);
  const alpha = (fC.values[3] || 1) / (tC.values[3] || 1);

  return recomposeColor({ values: [red, green, blue, alpha], type: "rgba" });
}
