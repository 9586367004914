import React from "react";
import moment from "moment";
import HorizontalCalendar from "../components/Statistic/HorizontalCalendar";
import Layout from "./Layout";

export default function Absences(): JSX.Element {
  return (
    <Layout>
      <HorizontalCalendar
        list={[
          {
            name: "Tobias",
            image: "/placeholder/tobi.png",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("month"),
                end: moment().startOf("month").add(6, "days"),
              },
            ],
          },
          {
            name: "Jojo",
            image: "/placeholder/jojo.png",
            progress: 1.5,
            absences: [
              {
                start: moment().startOf("week"),
                end: moment().startOf("week").add(2, "days"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Jojo",
            image: "/placeholder/jojo.png",
            progress: 1.5,
            absences: [
              {
                start: moment().startOf("week"),
                end: moment().startOf("week").add(2, "days"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Maciej",
            image: "/placeholder/maciej.png",
            progress: 0.7,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
          {
            name: "Ada",
            image: "/placeholder/ada.jpg",
            progress: 0.5,
            absences: [
              {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
              },
            ],
          },
        ]}
      />
    </Layout>
  );
}
