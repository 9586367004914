import { gql } from "@apollo/client";

export const startTimerMutation = gql`
  mutation startTimer($date: String) {
    timer: startTimer(startDate: $date) {
      id
      start
      end
      createdAt
      updatedAt
    }
  }
`;

export const stopTimerMutation = gql`
  mutation stopTimer($date: String) {
    timer: stopTimer(endDate: $date) {
      id
      start
      end
      createdAt
      updatedAt
    }
  }
`;

export const deleteTimerMutation = gql`
  mutation deleteTimer($id: Int!) {
    timer: deleteTimer(id: $id) {
      id
      start
      end
      createdAt
      updatedAt
    }
  }
`;

export const stopPauseMutation = gql`
  mutation stopPause($date: String) {
    timer: stopPause(endDate: $date) {
      id
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const startPauseMutation = gql`
  mutation startPause($date: String) {
    timer: startPause(startDate: $date) {
      id
      start
      end
      createdAt
      updatedAt
    }
  }
`;

export type StartTimerInput = {
  date: Date;
};

export type TimerResultType = {
  timer: {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    start: Date;
    end?: Date;
  };
};
export type RefreshTokenMutationResult = {
  refreshToken: string;
};
