import React from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { localize } from "../localization";

import { Hotel, HotTub } from "@material-ui/icons";
import Layout from "./Layout";
import WeekOverview from "../components/Durations/WeekOverview";
export default function Dashboard(): JSX.Element {
  return (
    <Layout>
      <Container>
        <Box paddingY={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Paper>
                <WeekOverview date={new Date()} />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={7}>
              <Paper>
                <Box padding={1}>
                  <Typography variant="h1">{localize`Absences`}</Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Avatar>
                          <Hotel />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText>Krank</ListItemText>
                    </ListItem>
                    <Divider variant="inset" />
                    <ListItem>
                      <ListItemIcon>
                        <Avatar>
                          <HotTub />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText>Urlaub</ListItemText>
                    </ListItem>
                    <Divider variant="inset" />
                  </List>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
}
