import { ThemeOptions } from "@material-ui/core";
import ThemeShadows from "./ThemeShadows";

export const overrides = {
  MuiAppBar: {
    colorDefault: {
      backgroundColor: "#fff",
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 18,
    },
  },
  MuiFilledInput: {
    root: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
};

export default {
  palette: {
    primary: {
      main: "#19b306",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffa252",
      contrastText: "#fff",
    },
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 20,
  shadows: ThemeShadows,
  overrides,
  typography: {
    fontFamily: [
      "ui-rounded",
      '"SF Pro Rounded"',
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "3em",
    },
    h2: {
      fontSize: "2.7em",
    },
    h3: {
      fontSize: "2.4em",
    },
    h4: {
      fontSize: "2.2em",
    },
    h5: {
      fontSize: "1.5em",
    },
    h6: {
      fontSize: "1.2em",
    },
  },
} as ThemeOptions;
