import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation login($email: String!, $password: String!, $mfaToken: String) {
    login(email: $email, password: $password, mfaToken: $mfaToken) {
      status
      jwtToken
    }
  }
`;

export const refreshToken = gql`
  mutation refreshToken {
    refreshToken
  }
`;

export type LoginMutationInput = {
  email: string;
  password: string;
  mfaToken?: string;
};

export type LoginMutationResult = {
  login: {
    status: "SUCCESS" | "FAILURE" | "MFA_CHALLENGE";
    jwtToken?: string;
    mfaType?: string;
  };
};
export type RefreshTokenMutationResult = {
  refreshToken: string;
};
