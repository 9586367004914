import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import {
  singleTimerQuery,
  SingleTimerQueryInput,
} from "../../gql/queries/timerQueries";
import { Timer } from "../../gql/queries/weeksQuery";
import { localize } from "../../localization";
import Grid from "../Helper/Grid";

export default function TimerEditView({
  open,
  timerId,
  onClose,
}: TimerEditViewProps) {
  const { data } = useQuery<{ timers: Timer[] }, SingleTimerQueryInput>(
    singleTimerQuery,
    { variables: { id: timerId } }
  );

  const [timer, setTimer] = useState<Timer | undefined>(undefined);

  useEffect(() => {
    setTimer(data?.timers[0]);
  }, [data]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{localize`Edit timer`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              type="time"
              value={moment(timer?.start).format("HH:mm")}
            />
          </Grid>
          <Grid item>
            <TextField type="time" value={moment(timer?.end).format("HH:mm")} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export type TimerEditViewProps = {
  open: boolean;
  timerId: number;
  onClose?: () => void;
};
