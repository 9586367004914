import { gql } from "@apollo/client";

export const registerMutation = gql`
  mutation register(
    $email: String!
    $company: String!
    $firstname: String!
    $lastname: String!
    $password: String!
    $passwordRepeat: String!
  ) {
    register(
      email: $email
      company: $company
      firstName: $firstname
      lastName: $lastname
      password: $password
      passwordRepeat: $passwordRepeat
    ) {
      status
      message
    }
  }
`;

export type RegistrationMutationInput = {
  email: string;
  company: string;
  firstname: string;
  lastname: string;
  password: string;
  passwordRepeat: string;
};

export type RegistrationMutationResult = {
  register: {
    status: "SUCCESS" | "FAILURE";
    message?: string;
  };
};
