import React, { useState, MouseEvent, ReactNode } from "react";
import { Menu, MenuProps as MuiMenuProps, IconButton } from "@material-ui/core";
import { ActionSheetItem, ActionSheetItemProps } from "./ActionSheetItem";
import { MoreVert } from "@material-ui/icons";

export interface ActionSheetProps extends Omit<MuiMenuProps, "open"> {
  /**
   * A new way of adding actions to a sheet
   */
  actions?: Array<ActionSheetItemProps | ActionSheetActionShort>;
  /**
   * Color of icon
   */
  color?: "inherit" | "default" | "primary" | "secondary" | undefined;

  stopPropagationOnOpen?: boolean;

  className?: string;
}

export const ActionSheet = ({
  actions,
  children,
  className,
  stopPropagationOnOpen = false,
  color = "inherit",
  ...rest
}: ActionSheetProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const close = () => setAnchorEl(undefined);

  return (
    <div className={className}>
      <IconButton
        color={color}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          if (stopPropagationOnOpen) event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        {...rest}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={(event: MouseEvent) => {
          if (stopPropagationOnOpen) event.stopPropagation();
          close();
        }}
      >
        {actions?.map((action, index) => {
          let theAction: ActionSheetItemProps;
          if (isShortAction(action)) {
            const [label, onClick, icon] = action;

            const IconType = icon;
            let IconComponent: ReactNode;

            if (IconType !== undefined) {
              IconComponent = <IconType />;
            }

            theAction = {
              label,
              onClick,
              icon: IconComponent,
            };
          } else {
            theAction = action;
          }

          const { label, icon, onClick, confirm, ...rest } = theAction;

          return (
            <ActionSheetItem
              label={label}
              icon={icon}
              onClick={onClick}
              confirm={confirm}
              close={close}
              key={`${index}+${label}`}
              {...rest}
            />
          );
        })}
        {children}
      </Menu>
    </div>
  );
};

function isShortAction(
  action: ActionSheetActionShort | ActionSheetItemProps
): action is ActionSheetActionShort {
  return (action as ActionSheetItemProps).label === undefined;
}

export type ActionSheetActionShort = [string, () => void, React.ElementType?];
