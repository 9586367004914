import React, { useContext } from "react";
import { Box, Container } from "@material-ui/core";
import AppBar from "../components/AppBar";
import { AuthenticationContext } from "../components/Auth/Authenticator";
import { Alert } from "@material-ui/lab";
import { localize } from "../localization";

export default function Layout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { networkError } = useContext(AuthenticationContext);
  return (
    <>
      <AppBar />
      {networkError && (
        <Container>
          <Box paddingTop={1}>
            <Alert severity="error">{localize`Servers not reachable. Information: ${networkError.message}. Try to refetch every 5 seconds.`}</Alert>
          </Box>
        </Container>
      )}
      {children}
    </>
  );
}
