import { gql } from "@apollo/client";

export const singleTimerQuery = gql`
  query singleTimer($id: Int!) {
    timers(where: { id: { equals: $id } }) {
      type
      start
      end
    }
  }
`;

export type SingleTimerQueryInput = { id: number };
