import { makeStyles, Theme, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import colorByProgress, {
  darkerColorByProgress,
} from "../utils/colorByProgress";

export default function CircularProgress({
  value = 0,
  size = 200,
  reduction = 0,
  strokeWidth = 6,
  ballStrokeWidth = 10,
  max = 1,
  hideText = false,
  subtitle,
  showBall = false,
  background,
  transitionDuration = 200,
  transitionTimingFunction = "ease",
  gradient,
}: CircularProgressProps): JSX.Element {
  const [progress, setProgress] = useState(() => (value / max) * 100);
  const [unique] = useState(() => Math.random().toString());
  const theme = useTheme();

  const center = size / 2;
  const height = size || center + center * Math.cos(reduction * Math.PI);
  const rotate = 90 + 180 * reduction;
  const r = center - strokeWidth / 2 - ballStrokeWidth / 2;
  const circumference = Math.PI * r * 2;
  const calcProgress = Math.min(100, progress);
  const offset = (circumference * (100 - calcProgress * (1 - reduction))) / 100;

  useEffect(() => setProgress((value / max) * 100), [value, max]);

  const classes = useStyles({ size });

  if (background === undefined) {
    background = theme.palette.background.default;
  }

  return (
    <div>
      <div className={classes.wrapper}>
        <svg viewBox={`0 0 ${size} ${height}`} className={classes.svg}>
          <defs>
            <linearGradient
              id={"gradient" + unique}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              {gradient &&
                gradient.map(({ stop, color }) => (
                  <stop
                    key={stop}
                    offset={stop * 100 + "%"}
                    stopColor={color}
                  />
                ))}
              {gradient === undefined && (
                <>
                  <stop
                    data-default-gradients
                    offset={0}
                    stopColor={darkerColorByProgress(progress / 100)}
                  />
                  <stop
                    offset={1}
                    stopColor={colorByProgress(progress / 100)}
                  />
                </>
              )}
            </linearGradient>
          </defs>
          {!hideText && (
            <text
              x={center}
              y={center}
              textAnchor="middle"
              fontSize="30"
              fill={theme.palette.text.primary}
            >
              {Math.round(progress)}%
            </text>
          )}
          <text
            x={center}
            y={center + (30 * 3) / 4}
            textAnchor="middle"
            fill={theme.palette.text.secondary}
          >
            {subtitle}
          </text>
          <circle
            transform={`rotate(${rotate} ${center} ${center})`}
            id="path"
            cx={center}
            cy={center}
            r={r}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={circumference * reduction}
            fill="none"
            stroke={background}
            strokeLinecap="round"
          ></circle>
          <circle
            style={{
              transition: `stroke-dashoffset ${transitionDuration}ms ${transitionTimingFunction}`,
            }}
            transform={`rotate(${rotate} ${center} ${center})`}
            id="path"
            cx={center}
            cy={center}
            r={r}
            strokeWidth={strokeWidth}
            strokeDasharray={`${circumference}`}
            strokeDashoffset={offset}
            fill="none"
            stroke={`url(#gradient${unique})`}
            strokeLinecap="round"
          ></circle>
          {showBall && (
            <circle
              style={{
                transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`,
              }}
              transform={`rotate(${rotate} ${center} ${center})`}
              id="path"
              cx={center}
              cy={center}
              r={r}
              strokeWidth={ballStrokeWidth}
              strokeDasharray={`1 ${circumference}`}
              strokeDashoffset={offset}
              fill="none"
              stroke={`url(#gradient${unique})`}
              strokeLinecap="round"
            ></circle>
          )}
        </svg>
      </div>
    </div>
  );
}

export type CircularProgressProps = {
  value?: number;
  size?: number;
  strokeWidth?: number;
  ballStrokeWidth?: number;
  max?: number;
  showBall?: boolean;
  hideText?: boolean;
  transitionTimingFunction?: string;
  transitionDuration?: number;
  background?: string;
  subtitle?: string;
  /**
   * Percentage of the circle that is removed. Set to 0 o have a closed circle. Default uses 3/4 of the circle (75%) so .25 reduction
   */
  reduction?: number;
  gradient?: CircularProgressGradient[];
};

export type CircularProgressGradient = {
  stop: number;
  color: string;
};

const useStyles = makeStyles<Theme, { size: number }>({
  wrapper: {
    fontFamily: [
      "ui-rounded",
      "SF Pro Rounded",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    maxWidth: ({ size }) => size,
    maxHeight: ({ size }) => size,
  },
  svg: { display: "block", width: "100%", height: "100%" },
});
