import React, { ReactNode, forwardRef } from "react";

import MenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Grid from "../Grid";
import { localize } from "../../../localization";
import Confirmation from "./Confirmation";

export interface ActionSheetItemProps extends MuiMenuItemProps {
  label: string;
  icon?: ReactNode;
  confirm?: string | true | { title: string; question?: string };
  close?: () => void;
  css?: null;
}

export const ActionSheetItem = forwardRef<HTMLElement, ActionSheetItemProps>(
  function WrappedMenuItem(
    {
      confirm,
      onClick,
      label,
      children,
      close,
      icon,
      ...rest
    }: ActionSheetItemProps,
    ref
  ) {
    const handleClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      onClick?.(event);
      close?.();
    };
    const menuItem = (
      <MenuItem
        onClick={handleClick}
        {...(rest as MuiMenuItemProps)}
        button={true}
        innerRef={ref}
      >
        <Grid container spacing={3}>
          <Grid item grow>
            <Typography variant="inherit" noWrap>
              {label || children}
            </Typography>
          </Grid>
          {Boolean(icon) && <Grid item>{icon}</Grid>}
        </Grid>
      </MenuItem>
    );

    if (confirm) {
      let title = localize`Are you sure?`;
      let question: string | undefined = undefined;

      if (typeof confirm === "string") {
        title = confirm;
      } else if (confirm !== true) {
        title = confirm.title;
        question = confirm.question;
      }

      return (
        <Confirmation title={title} question={question} onCancel={close}>
          {menuItem}
        </Confirmation>
      );
    }
    return menuItem;
  }
);
