import React, { useEffect, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  // List,
  makeStyles,
  // Paper,
  Typography,
} from "@material-ui/core";
import useDark from "../components/Theme/useDark";
// import PersonDayOverview from "../components/Statistic/PersonDayOverview";
// import { ToysOutlined } from "@material-ui/icons";
import { localize } from "../localization";
// import Avatar from "../components/Statistic/Avatar";
// import PersonWeekOverview from "../components/Statistic/PersonWeekOverview";
// import moment from "moment";
import Lottie from "react-lottie";
import WorkAnimation from "../lottie/working.json";

export default function HomePage(): JSX.Element {
  const [dark] = useDark();
  const classes = useStyles();
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvas.current) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "/gradient.js";

      canvas.current.parentNode?.appendChild(script);
    }
  }, [canvas]);

  return (
    <div className="home">
      <div className={classes.canvasWrapper}>
        <canvas
          id="gradient-canvas"
          className={classes.canvas}
          ref={canvas}
          data-js-darken-top
          data-transition-in
        ></canvas>
      </div>
      <Container>
        <div className={classes.logoWrapper}>
          <img
            src={`/logo${dark ? "_dark" : ""}.png`}
            alt="TimeTie"
            width="500"
          />
        </div>

        <Grid container justify="space-between" spacing={3} alignItems="center">
          <Grid item xs={12} sm={5}>
            <Box padding={2}>
              <Typography
                variant="h1"
                gutterBottom
              >{localize`Time tracking done right!`}</Typography>
              <Typography
                gutterBottom
              >{localize`You're at the right place. We love time tracking and you will see it the first time you hit the play button. We made it our mission, that time tracking is fast, reliable and does not take too much from your working time.`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Lottie
              isPaused={false}
              isStopped={false}
              options={{
                animationData: WorkAnimation,
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <div className={classes.gradient}>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={8}>
              <img src="/responsive.png" alt="TimeTie on all devices" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box color="white">
                <Typography variant="h2">{localize`On all devices`}</Typography>
                <Typography variant="body1">{localize`Book time anywhere anytime`}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* <Container>
        <Grid container justify="space-between" spacing={3} alignItems="center">
          <Grid item xs={8}>
            <Paper>
              <Box paddingX={2} paddingY={1}>
                <Typography variant="h2" gutterBottom>
                  {localize`Time tracking like it should be`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {localize`Lovely UI to see more data at glance.`}
                </Typography>
                <Typography variant="body1">
                  {localize`In most places the profile picture tells you more that other UI's on a whole page.`}
                </Typography>
                <Box padding={2} paddingBottom={0}>
                  <Grid container justify="center" spacing={2}>
                    <Grid item>
                      <div>
                        <Avatar
                          image="/placeholder/tobi.png"
                          progress={0.4}
                          size={96}
                        />
                      </div>
                      <br />
                      <Typography align="center">{localize`${"40%"} done`}</Typography>
                    </Grid>
                    <Grid item>
                      <div>
                        <Avatar
                          image="/placeholder/tobi.png"
                          progress={1}
                          size={96}
                        />
                      </div>
                      <br />
                      <Typography align="center">{localize`${"100%"} done`}</Typography>
                    </Grid>
                    <Grid item>
                      <div>
                        <Avatar
                          image="/placeholder/tobi.png"
                          progress={1.3}
                          offline
                          size={96}
                        />
                      </div>
                      <br />
                      <Typography align="center">{localize`Already done and offline`}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Box padding={1}>
                <Typography variant="h4" gutterBottom>
                  {localize`Your team`}
                </Typography>
                <List>
                  <PersonDayOverview
                    name="Johanna"
                    image="/placeholder/jojo.png"
                    progress={1}
                  />
                  <PersonDayOverview
                    name="Ada"
                    image="/placeholder/ada.jpg"
                    progress={0.51}
                  />
                  <PersonDayOverview
                    icon={
                      <ToysOutlined
                        style={{
                          background: "red",
                          color: "#fff",
                          padding: 3,
                          borderRadius: "30px",
                        }}
                      />
                    }
                    name="Maciej"
                    image="placeholder/maciej.png"
                    progress={0.1}
                  />
                  <PersonDayOverview
                    name="Tobi"
                    image="/placeholder/tobi.png"
                    progress={0.25}
                  />
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3} alignItems="center">
          <Grid item xs={12} sm={5} lg={6}>
            <PersonWeekOverview
              name="Tobias Zimmermann"
              image="/placeholder/tobi.png"
              startOfWeek={moment().startOf("week").toDate()}
            />
          </Grid>
        </Grid>
      </Container> */}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  canvasWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: -1,
    height: "50vh",
    transform: "skewY(-6deg) translateY(-50%)",
  },
  canvas: {
    width: "100%",
    height: "100%",
    "--gradient-color-1": "#be3010",
    "--gradient-color-2": "#6CB5F8",
    "--gradient-color-3": "#E5846D",
    "--gradient-color-4": "#fff",
  },
  gradient: {
    background: "linear-gradient(180deg, #2994FF 9.03%, #53F0B7 100%)",
  },
  logoWrapper: {
    background: theme.palette.background.default + "a9",
    backdropFilter: "blur(10px)",
    display: "inline-block",
    borderRadius: 40,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
