import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import {
  Avatar as MuiAvatar,
  Badge,
  makeStyles,
  Theme,
} from "@material-ui/core";
import colorByProgress, {
  darkerColorByProgress,
} from "../../utils/colorByProgress";
import { Check } from "@material-ui/icons";

export default forwardRef(function Avatar(
  {
    name,
    image,
    progress,
    icon,
    size = 48,
    offline = false,
    ...divProps
  }: AvatarProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const strokeWidth = 15;
  const classes = useStyles({ progress, size, offline });
  const width = 200;
  const center = width / 2;
  const rotate = 90;
  const r = center - strokeWidth / 2;
  const circumference = Math.PI * r * 2;
  const calcProgress = Math.min(1, progress);
  const offset = circumference * (1 - calcProgress);
  const [unique] = React.useState(() => Math.random().toString());

  const secondRing = progress > 1;
  const secondCalcProgress = Math.min(1, progress - 1);
  const secondOffset = circumference * (1 - secondCalcProgress);

  if (icon === undefined && progress >= 1) {
    icon = (
      <MuiAvatar className={classes.doneAvatar}>
        <Check />
      </MuiAvatar>
    );
  }

  return (
    <div className={classes.root} {...divProps} ref={ref}>
      <Badge
        badgeContent={icon}
        overlap="circle"
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MuiAvatar src={image} className={classes.avatar} alt={name} />
      </Badge>
      <svg viewBox={`0 0 ${width} ${width}`} className={classes.svg}>
        <linearGradient
          id={"gradient" + unique}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset={0} stopColor={darkerColorByProgress(progress)} />
          <stop offset={1} stopColor={colorByProgress(progress)} />
        </linearGradient>
        <linearGradient
          id={"gradient1" + unique}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset={0} stopColor={colorByProgress(progress)} />
          <stop offset={0.3} stopColor={darkerColorByProgress(progress)} />
        </linearGradient>
        <circle
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeDasharray={circumference}
          strokeWidth={strokeWidth}
          strokeDashoffset={offset}
          fill="none"
          stroke={`url(#gradient${unique})`}
          strokeLinecap="round"
        />
        {secondRing && (
          <circle
            transform={`rotate(${rotate} ${center} ${center})`}
            id="path*more"
            cx={center}
            cy={center}
            r={r}
            strokeDasharray={circumference}
            strokeWidth={strokeWidth}
            strokeDashoffset={secondOffset}
            fill="none"
            stroke={`url(#gradient1${unique})`}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </div>
  );
});

export type AvatarProps = {
  name?: string;
  image?: string;
  progress: number;
  icon?: ReactNode;
  size?: number;
  offline?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const useStyles = makeStyles<
  Theme,
  { progress: number; size: number; offline: boolean }
>(_ => ({
  root: {
    position: "relative",
    width: ({ size }) => size + size / 3,
    height: ({ size }) => size + size / 3,
    padding: ({ size }) => size / 6,
  },
  svg: {
    position: "absolute",
    display: "block",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
  },
  avatar: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    filter: ({ offline }) => (offline ? "grayscale(0.8)" : undefined),
    opacity: ({ offline }) => (offline ? 0.8 : 1),
  },
  doneAvatar: {
    width: ({ size }) => size / 2.4,
    height: ({ size }) => size / 2.4,
    color: "#fff",
    boxShadow: "0 1px 4px -1px #000",
    background: ({ progress }) =>
      `linear-gradient(left,${colorByProgress(
        progress
      )},${darkerColorByProgress(progress)})`,
    "& svg": {
      width: ({ size }) => size / 3,
      height: ({ size }) => size / 3,
    },
  },
}));
