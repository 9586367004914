import { gql } from "@apollo/client";

export default gql`
  query days($start: String!, $end: String) {
    workingTime(start: $start, end: $end) {
      weeks {
        date
        scheduledWorkingTime
        achievedWorkingTime
        days {
          date
          scheduledWorkingTime
          achievedWorkingTime
          timers {
            id
            start
            end
            type
          }
        }
      }
    }
  }
`;

export enum TimerType {
  pause = "PAUSE",
  timer = "TIMER",
}
export type Timer = {
  id: number;
  start: string;
  end: string | null;
  type: TimerType;
};
export type WeeksQueryInputType = {
  start: String;
  end?: String;
};
export type WeeksResultType = {
  workingTime: {
    weeks: {
      scheduledWorkingTime: string;
      achievedWorkingTime: string;
      date: string;
      days: DayType[];
    }[];
  };
};

export type WeekType = {
  date: string;
  scheduledWorkingTime: string;
  achievedWorkingTime: string;
  days: DayType[];
};

export type DayType = {
  date: string;
  scheduledWorkingTime: string;
  achievedWorkingTime: string;
  timers: Timer[] | null;
};
