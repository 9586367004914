import React, { useState } from "react";
import { ListItem, makeStyles, Typography } from "@material-ui/core";
import {
  ArrowForwardRounded,
  ArrowBackRounded,
  PauseRounded,
} from "@material-ui/icons";
import moment from "moment";
import weeksQuery, { Timer, TimerType } from "../../gql/queries/weeksQuery";
import Grid from "../Helper/Grid";
import { localize } from "../../localization";
import { ActionSheet } from "../Helper/ActionSheet";
import { useMutation } from "@apollo/client";
import { deleteTimerMutation } from "../../gql/mutations/timerMutations";
import TimerEditView from "./TimerEditView";
import Time from "./Time";

export default function TimerView({
  timer: { start, end, type, id },
}: TimerViewProps) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  const arrowColor =
    type === TimerType.pause ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0.05)";

  const duration = moment.duration(
    moment(end === null ? undefined : end).diff(moment(start))
  );

  

  const [deleteTimer] = useMutation(deleteTimerMutation, {
    variables: { id },
    refetchQueries: [
      {
        query: weeksQuery,
        variables: {
          start: moment(start).startOf("week").toISOString(),
          end: moment(start).endOf("week").toISOString(),
        },
      },
    ],
  });

  return (
    <>
      <ListItem className={classes.timer}>
        <>
          <svg
            className={classes.arrow}
            viewBox="0 0 100 50"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
          >
            <path d="M0 0L50 0L55 25L50 50L0 50Z" fill={arrowColor} />
          </svg>
          {type === TimerType.pause ? (
            <PauseRounded color="primary" className={classes.pauseIcon} />
          ) : (
            <>
              <ArrowForwardRounded color="primary" className={classes.inIcon} />
              <ArrowBackRounded color="secondary" className={classes.outIcon} />
            </>
          )}
          <Typography className={classes.start}>
            {moment(start).format("HH:mm:ss")}
          </Typography>
          <Typography
            className={classes.end}
            color={end === null ? "primary" : "textPrimary"}
          >
            {end !== null ? moment(end).format("HH:mm:ss") : localize`now`}
          </Typography>
          <Grid container direction="column" className={classes.info}>
            <Grid item>
              <Time seconds={duration.asSeconds()} isRunning={end === null} />
            </Grid>
          </Grid>

          <ActionSheet
            className={classes.more}
            stopPropagationOnOpen
            actions={[
              {
                label: localize`edit timer`,
                onClick: () => setEdit(true),
              },
              {
                label: localize`delete timer`,
                confirm: localize`Are you sure to delete the timer?`,
                onClick: () => deleteTimer(),
              },
            ]}
          />
        </>
      </ListItem>
      <TimerEditView open={edit} timerId={id} onClose={() => setEdit(false)} />
    </>
  );
}

export type TimerViewProps = {
  timer: Timer;
};

const useStyles = makeStyles({
  timer: {
    position: "relative",
    display: "grid",
    gridTemplateAreas: `"icon startTime info more"
    "icon endTime info more"`,
    gridTemplateColumns: "auto 1fr auto auto",
    gap: 5,
    margin: "1px 0",
  },
  pauseIcon: {
    gridArea: "icon",
    gridColumn: 1,
    marginRight: 10,
  },
  inIcon: {
    gridColumn: 1,
    gridRow: 1,
    marginRight: 10,
  },
  outIcon: {
    gridColumn: 1,
    gridRow: 2,
    marginRight: 10,
  },
  start: {
    gridArea: "startTime",
  },
  end: {
    gridArea: "endTime",
  },
  info: {
    gridArea: "info",
  },
  more: {
    gridArea: "more",
  },
  arrow: {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
  },
});
