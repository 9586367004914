import React, { useEffect, useState } from "react";
import { Button, Fab, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  PauseRounded,
  PlayArrowRounded,
  StopRounded,
} from "@material-ui/icons";
import moment from "moment";
import {
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";
import weeksQuery, {
  Timer,
  TimerType,
  WeeksQueryInputType,
  WeeksResultType,
} from "../../gql/queries/weeksQuery";
import { useMemo } from "react";
import {
  startPauseMutation,
  startTimerMutation,
  stopTimerMutation,
  stopPauseMutation,
} from "../../gql/mutations/timerMutations";
import { localize } from "../../localization";

export default function MiniTimerControls() {
  const classes = useStyles();

  const [secondsToday, setSecondsToday] = useState(0);
  const { data, refetch } = useQuery<WeeksResultType, WeeksQueryInputType>(
    weeksQuery,
    {
      variables: {
        start: moment().startOf("week").toISOString(),
        end: moment().endOf("week").toISOString(),
      },
    }
  );

  const update = useMemo(() => (data ? new Date() : new Date()), [data]);
  const today = useMemo(() => {
    return data?.workingTime.weeks[0]?.days.find(
      ({ date }) => date === moment().format("YYYY-MM-DD")
    );
  }, [data]);

  const mutationOptions = (): MutationFunctionOptions<
    Partial<WeeksQueryInputType> | undefined,
    OperationVariables
  > => ({
    variables: {
      date: moment().toISOString(),
    },
  });

  const [startTimer] = useMutation(startTimerMutation, {
    onCompleted: refetch,
  });
  const [stopTimer] = useMutation(stopTimerMutation, {
    onCompleted: refetch,
  });

  const [stopPause] = useMutation(stopPauseMutation, {
    onCompleted: refetch,
  });
  const [startPause] = useMutation(startPauseMutation, {
    onCompleted: refetch,
  });

  const activeTimer = useMemo(() => {
    return data?.workingTime.weeks[0]?.days.reduce<Timer | undefined>(
      (result, { timers }) => {
        const runningTimer = timers?.find(({ end }) => end === null);

        if (runningTimer !== undefined) {
          return runningTimer;
        }
        return result;
      },
      undefined
    );
  }, [data]);

  useEffect(() => {
    const setValue = () => {
      setSecondsToday(
        moment.duration(today?.achievedWorkingTime ?? "").asSeconds() +
          (activeTimer && activeTimer.type === TimerType.timer
            ? (Date.now() - update.getTime()) / 1000
            : 0)
      );
    };
    setValue();
    const timeInterval = setInterval(setValue, 500);
    return () => clearInterval(timeInterval);
  }, [today, activeTimer, update]);

  return (
    <Grid container wrap="nowrap" alignItems="center">
      {activeTimer && (
        <Fab
          size="small"
          className={classes.fab}
          onClick={async () => {
            if (activeTimer.type === TimerType.pause) {
              await stopPause(mutationOptions());
            } else {
              await startPause(mutationOptions());
            }
          }}
        >
          {activeTimer?.type === TimerType.timer ? (
            <PauseRounded />
          ) : (
            <PlayArrowRounded />
          )}
        </Fab>
      )}
      {activeTimer && activeTimer.type === TimerType.pause && (
        <Typography>
          {moment
            .duration(0, "second")
            .format("[" + localize`Break:` + " ]HH:mm:ss", { trim: false })}
        </Typography>
      )}
      {(activeTimer === undefined || activeTimer.type === TimerType.timer) && (
        <Button
          variant="contained"
          color={activeTimer ? "secondary" : "primary"}
          startIcon={activeTimer ? <StopRounded /> : <PlayArrowRounded />}
          className={classes.monospace}
          onClick={() => {
            if (activeTimer) {
              activeTimer.type === TimerType.timer ? stopTimer() : stopPause();
            } else {
              startTimer(mutationOptions());
            }
          }}
        >
          {moment
            .duration(secondsToday, "second")
            .format("HH:mm:ss", { trim: false })}
        </Button>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  monospace: {
    fontFamily: "monospace",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  fab: {
    marginRight: theme.spacing(),
  },
}));
