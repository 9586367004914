import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CircularProgress from "../CircularProgress";
import Grid from "../Helper/Grid";
import weeksQuery, { DayType } from "../../gql/queries/weeksQuery";
import moment from "../../utils/moment";
import TimerView from "./TimerView";
import { ActionSheet } from "../Helper/ActionSheet";
import { localize } from "../../localization";
import { deleteTimerMutation } from "../../gql/mutations/timerMutations";
import { useMutation } from "@apollo/client";
import Time from "./Time";

export default function DayOverview({
  day: { date, timers, achievedWorkingTime, scheduledWorkingTime },
}: {
  day: DayType;
}) {
  const start = moment(date);
  const achivedSeconds = moment.duration(achievedWorkingTime).asSeconds();
  const scheduledSeconds = moment.duration(scheduledWorkingTime).asSeconds();
  const progress =
    moment.duration(achievedWorkingTime).asSeconds() /
      moment.duration(scheduledWorkingTime).asSeconds() || 0;

  const isRunning = timers?.find(timer => timer.end === null) !== undefined;

  const [deleteTimer] = useMutation(deleteTimerMutation);

  return (
    <List>
      <ListItem>
        <Grid container justify="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <Grid container justify="center">
              <CircularProgress
                value={progress}
                hideText
                size={100}
                strokeWidth={15}
              />
            </Grid>
          </Grid>
          <Grid item grow>
            <Typography variant="h5">{start.format("dddd")}</Typography>
            <Typography variant="h5">{start.format("LL")}</Typography>
          </Grid>
          <Grid item>
            <Typography align="right" variant="h6">
              <Time seconds={achivedSeconds} isRunning={isRunning} />
            </Typography>
            <Typography align="right" variant="caption" display="block">
              <Time seconds={scheduledSeconds} />
            </Typography>
          </Grid>

          <Grid item>
            <ActionSheet
              actions={[
                {
                  label: localize`Delete all timers`,
                  confirm: localize`Do you really want to delete all timers for this day?`,
                  onClick: async () => {
                    const timerList = Array.from(timers || []);
                    for (const [index, timer] of timerList.entries()) {
                      await deleteTimer({
                        variables: { id: timer.id },
                        refetchQueries:
                          timerList.length - 1 === index
                            ? [
                                {
                                  query: weeksQuery,
                                  variables: {
                                    start: moment(start)
                                      .startOf("week")
                                      .toISOString(),
                                    end: moment(start)
                                      .endOf("week")
                                      .toISOString(),
                                  },
                                },
                              ]
                            : undefined,
                      });
                    }
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider />

      {timers?.map((timer, index) => (
        <TimerView timer={timer} key={timer.start} />
      ))}
      {(timers?.length ?? 0) === 0 && (
        <ListItem>
          <ListItemText primary={localize`no timers yet`} />
        </ListItem>
      )}
    </List>
  );
}
