import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  HttpLink,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import jwtDecode from "jwt-decode";
import { useCallback } from "react";
import { useMemo } from "react";
import {
  refreshToken,
  RefreshTokenMutationResult,
} from "../../gql/mutations/loginMutation";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import SignIn from "./SignIn";

export const AuthenticationContext = createContext<AuthenticatorContextType>(
  {}
);

export default function Authenticator({ children }: { children: ReactNode }) {
  const [token, setTokenState] = useState<string | undefined>(
    localStorage.getItem("token") || undefined
  );
  const [networkError, setNetworkError] = useState<
    Error | ServerError | ServerParseError
  >();

  useEffect(() => {
    if (networkError === undefined) return;
    const timeout = setTimeout(() => {
      setNetworkError(undefined);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [networkError]);

  const setToken = useCallback(
    (token: string | undefined) => {
      if (token === undefined) {
        localStorage.removeItem("token");
      } else {
        localStorage.setItem("token", token);
      }
      setTokenState(token);
    },
    [setTokenState]
  );

  const signOut = () => setToken(undefined);

  const { exp: expired } = token
    ? jwtDecode<{ exp: number }>(token || "")
    : { exp: undefined };

  const validToken = useMemo(() => {
    if (expired === undefined) return false;
    try {
      return expired <= Date.now();
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [expired]);

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError, response }) => {
      setNetworkError(networkError);

      if (graphQLErrors) {
        const isBearerError =
          graphQLErrors.find(
            ({ message }) => message === "Bearer token required"
          ) !== undefined;

        if (isBearerError) {
          if (response) {
            setToken(undefined);
            response.errors = undefined;
          }
        }
      }
    });

    const retryLink = new RetryLink({
      attempts: () => true,
      delay: () => 5000,
    });

    const httpLink = new HttpLink({
      uri: "https://api.timetie.app",
      headers: {
        Authentication: token !== undefined ? `Bearer ${token}` : "",
      },
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([retryLink, errorLink, httpLink]),
    });
  }, [token, setToken]);

  useEffect(() => {
    if (validToken === false || expired === undefined) return;
    const now = Date.now();

    const timeout = setTimeout(async () => {
      const { data, errors } = await client.mutate<RefreshTokenMutationResult>({
        mutation: refreshToken,
      });

      if (errors !== undefined) {
        console.error(errors);
        setToken(undefined);
      } else if (data !== null && data !== undefined) {
        setToken(data.refreshToken);
        console.log({ token: data.refreshToken });
      } else {
        setToken(undefined);
      }
    }, expired * 1000 - now - 10000);

    return () => clearTimeout(timeout);
  }, [validToken, setToken, expired, client]);

  return (
    <AuthenticationContext.Provider value={{ token, signOut, networkError }}>
      <ApolloProvider client={client}>
        {validToken && children}
        {validToken === false && <SignIn setToken={setToken} />}
      </ApolloProvider>
    </AuthenticationContext.Provider>
  );
}

type AuthenticatorContextType = {
  token?: string;
  signOut?: () => void;
  networkError?: Error | ServerError | ServerParseError;
};
