import React, { Fragment, useState } from "react";
import {
  Dialog,
  Divider,
  List,
  ListItem,
  Slide,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import "moment-duration-format";
import CircularProgress from "../CircularProgress";
import { localize } from "../../localization";
import DayOverview from "./DayOverview";
import Grid from "../Helper/Grid";
import { useQuery } from "@apollo/client";
import weeksQuery, {
  WeeksQueryInputType,
  WeeksResultType,
  DayType,
} from "../../gql/queries/weeksQuery";
import Time from "./Time";

export default function WeekOverview({ date }: WeekOverviewProps) {
  const realStartOfWeek = moment(date).startOf("week");

  const { data, error } = useQuery<WeeksResultType, WeeksQueryInputType>(
    weeksQuery,
    {
      variables: {
        start: realStartOfWeek.toISOString(),
        end: moment(realStartOfWeek).endOf("week").toISOString(),
      },
    }
  );

  const week = data?.workingTime.weeks[0];

  const days = week?.days || [];

  const isRunning =
    days.find(
      day => day.timers?.find(timer => timer.end === null) !== undefined
    ) !== undefined;

  console.log({ days, isRunning });

  const [openDay, setOpenDay] =
    useState<{ day: string; elem: HTMLDivElement }>();

  if (error) {
    return <h1>{error.message}</h1>;
  }

  const weekArchived = moment
    .duration(week?.achievedWorkingTime ?? 0)
    .asSeconds();
  const weekScheduled = moment
    .duration(week?.scheduledWorkingTime ?? 0)
    .asSeconds();

  const weekProgress = weekArchived / weekScheduled || 0;

  return (
    <>
      <List>
        <ListItem>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <Grid container justify="center">
                <CircularProgress
                  value={weekProgress}
                  hideText
                  size={70}
                  strokeWidth={10}
                />
              </Grid>
            </Grid>
            <Grid item grow>
              <Typography variant="h5">
                {localize`Week ${realStartOfWeek.format(
                  "w"
                )} / ${realStartOfWeek.format("Y")}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="right" variant="h6">
                <Time seconds={weekArchived} isRunning={isRunning} />
              </Typography>
              <Typography align="right" variant="caption" display="block">
                <Time seconds={weekScheduled} />
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        {days.map((day, i) => {
          const { date, scheduledWorkingTime, achievedWorkingTime, timers } =
            day;

          const progress =
            moment.duration(achievedWorkingTime).asSeconds() /
              moment.duration(scheduledWorkingTime).asSeconds() || 0;

          const dayScheduled = moment
            .duration(scheduledWorkingTime)
            .asSeconds();
          const dayArchived = moment.duration(achievedWorkingTime).asSeconds();

          const isRunning =
            timers?.find(timer => timer.end === null) !== undefined;

          return (
            <Fragment key={i}>
              <ListItem
                button
                onClick={event =>
                  setOpenDay({
                    elem: event.currentTarget,
                    day: day.date,
                  })
                }
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Grid container justify="center">
                      <CircularProgress
                        value={progress}
                        hideText
                        size={50}
                        strokeWidth={4}
                      />
                    </Grid>
                  </Grid>
                  <Grid item grow>
                    <Typography>{moment(date).format("dddd")}</Typography>
                    <Typography variant="caption">
                      {moment(date).format("LL")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="right">
                      <Time seconds={dayArchived} isRunning={isRunning} />
                    </Typography>
                    <Typography align="right" variant="caption" display="block">
                      <Time seconds={dayScheduled} />
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {i !== 6 && <Divider />}
            </Fragment>
          );
        })}
      </List>
      <Dialog
        open={Boolean(openDay)}
        onClose={() => setOpenDay(undefined)}
        TransitionComponent={Slide}
      >
        {openDay && (
          <DayOverview
            key={openDay.day}
            day={days.find(day => day.date === openDay.day) as DayType}
          />
        )}
      </Dialog>
    </>
  );
}

export type WeekOverviewProps = {
  date: Date;
};
