import React, { useRef, useState, useContext } from "react";
import {
  AppBar as MuiAppBar,
  Container,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { WbSunnyTwoTone, AccountBox } from "@material-ui/icons";
import useDark from "./Theme/useDark";
import Avatar from "./Statistic/Avatar";
import { localize } from "../localization";
import MiniTimerControls from "./Interactive/MiniTimerControls";
import { AuthenticationContext } from "./Auth/Authenticator";

export default function AppBar({ className }: { className?: string }) {
  const [dark, setDark] = useDark();
  const { signOut } = useContext(AuthenticationContext);
  const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <MuiAppBar className={className} position="relative" color="default">
      <Container>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <img
              src={`logo${dark ? "_dark" : ""}.png`}
              width="230"
              alt="TimeTie"
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item>
                <MiniTimerControls />
              </Grid>
              <Grid item>
                <Avatar
                  image="https://pim.sport-thieme.de/userimage/8"
                  progress={0.6}
                  size={42}
                  onClick={() => {
                    setMenuAnchor(ref.current);
                  }}
                  ref={ref}
                />
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={() => setMenuAnchor(null)}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={() => setDark(!dark)}>
                    <ListItemIcon>
                      <WbSunnyTwoTone />
                    </ListItemIcon>
                    <Typography>
                      {dark
                        ? localize`Turn off darkmode`
                        : localize`Turn on darkmode`}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={signOut}>
                    <ListItemIcon>
                      <AccountBox />
                    </ListItemIcon>
                    <Typography>{localize`Logout`}</Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MuiAppBar>
  );
}
