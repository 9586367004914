import React from "react";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ThemeProvider from "./components/Theme/ThemeProvider";
import HomePage from "./pages/HomePage";
import Absences from "./pages/Absences";
import Authenticator from "./components/Auth/Authenticator";

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <Authenticator>
        <Router>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/frontpage" component={HomePage} />
          <Route exact path="/absences" component={Absences} />
        </Router>
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
