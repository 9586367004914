import React, { useEffect, useRef, useState } from "react";
import {
  Divider,
  fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  useTheme,
} from "@material-ui/core";
import * as MomentObject from "moment";
import { extendMoment } from "moment-range";
import { Moment } from "moment";
import Avatar from "./Avatar";
import { Fragment } from "react";

const moment = extendMoment(MomentObject);

const dateBarHeight = 48;
const barHeight = 60;

export default function HorizontalCalendar({
  list,
  visibleDays = 18,
  start,
  end,
}: HorizontalCalendarProps) {
  const dayLegendRef = useRef<SVGSVGElement>(null);
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [svgWrapperRef, setSvgWrapperRef] = useState<HTMLDivElement | null>(
    null
  );
  const [{ width, height }, setSizes] = useState({ width: 0, height: 0 });
  const [stick, setStick] = useState(false);
  const classes = useStyles({ stick });
  const theme = useTheme();
  const [range] = useState(() =>
    moment.range(
      start || moment().subtract(6, "month"),
      end || moment().add(6, "month")
    )
  );

  useEffect(() => {
    setSizes({
      width: svgWrapperRef?.clientWidth || 0,
      height: svgWrapperRef?.clientHeight || 0,
    });
  }, [svgWrapperRef]);

  useEffect(() => {
    const handleResize = () => {
      setSizes({
        width: svgRef?.clientWidth || 0,
        height: svgRef?.clientHeight || 0,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [svgRef]);

  const dayRange = Array.from(range.by("day"));

  useEffect(() => {
    const handleScroll = () => {
      setStick(window.scrollY > (svgWrapperRef?.offsetTop || 0));
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [svgWrapperRef]);

  return (
    <div className={classes.calendarWrapper}>
      <Paper square elevation={0} className={classes.userListWrapper}>
        <div className={classes.listDateBarPlaceholder} />
        <List className={classes.userList}>
          {list.map(({ name, image, progress }, index) => (
            <Fragment key={name + index}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar
                    name={name}
                    image={image}
                    progress={progress || 0}
                    size={35}
                  />
                </ListItemAvatar>
                <ListItemText>{name}</ListItemText>
              </ListItem>
              {list.length !== index + 1 && <Divider />}
            </Fragment>
          ))}
        </List>
      </Paper>
      <div className={classes.svgWrapper} ref={ref => setSvgWrapperRef(ref)}>
        <svg
          className={classes.svgDateLegend}
          width={(dayRange.length * width) / visibleDays}
          height={dateBarHeight}
          ref={dayLegendRef}
        >
          <g id="days">
            {dayRange.map((day, index) => {
              const dayWidth = width / visibleDays;
              const x = dayWidth * (index + 1) - dayWidth / 2;
              return (
                <g key={`s${index}`}>
                  <text
                    x={x}
                    y={dateBarHeight / 3}
                    dy="0.5em"
                    className={classes.svgDayLabel}
                  >
                    {day.format("dd")}
                  </text>

                  <text
                    x={x}
                    y={dateBarHeight / 1.5}
                    dy="0.5em"
                    className={classes.svgDayDateLabel}
                  >
                    {day.format("DD MMM")}
                  </text>
                </g>
              );
            })}
            <line
              x1={0}
              y1={dateBarHeight}
              x2={width}
              y2={dateBarHeight}
              stroke={theme.palette.divider}
            />
          </g>
        </svg>
        <svg
          className={classes.svg}
          ref={ref => setSvgRef(ref)}
          width={(dayRange.length * width) / visibleDays}
          height={list.length * barHeight + 0.5}
        >
          <defs>
            <clipPath id="clip">
              <rect width={width} height={height} />
            </clipPath>
          </defs>
          <g clipPath="#clip">
            <g id="weekends">
              {dayRange.map((day, index) => {
                const dayWidth = width / visibleDays;
                const x = dayWidth * index;

                if (day.isoWeekday() < 6) {
                  return <Fragment key={index}></Fragment>;
                }
                return (
                  <rect
                    key={index}
                    x={x}
                    y={dateBarHeight}
                    width={dayWidth}
                    height={height}
                    fill={fade(theme.palette.primary.main, 0.05)}
                  ></rect>
                );
              })}
            </g>
            {/* horizontal grid */}
            <g>
              {list.map((_, index) => {
                const y = -0.5 + dateBarHeight + (index + 1) * barHeight;
                return (
                  <line
                    key={index}
                    x1={0}
                    x2={(dayRange.length * width) / visibleDays}
                    y1={y}
                    y2={y}
                    stroke={theme.palette.divider}
                    strokeWidth="1"
                    strokeDasharray="5,5"
                  />
                );
              })}
            </g>
            {/* vertical grid */}
            <g>
              {dayRange.map((_, index) => {
                const dayWidth = width / visibleDays;
                const x = dayWidth * index;
                return (
                  <line
                    key={index}
                    x1={x}
                    x2={x}
                    y1={0}
                    y2={-0.5 + dateBarHeight + list.length * barHeight}
                    stroke={theme.palette.divider}
                    strokeWidth="1"
                    strokeDasharray="5,5"
                  />
                );
              })}
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  calendarWrapper: {
    display: "flex",
    minHeight: "calc(100vh - 80px)",
  },
  listDateBarPlaceholder: {
    height: dateBarHeight,
  },
  listItem: {
    height: 59,
  },
  svgWrapper: {
    position: "relative",
    width: "calc(100% - 300px)",
    overflowX: "scroll",
    display: "flex",
    flexDirection: "column",
  },
  svgDayLabel: {
    textAnchor: "middle",
    fill: theme.palette.text.secondary,
  },
  svgDayDateLabel: {
    textAnchor: "middle",
    fontSize: "0.9rem",
    fill: theme.palette.text.primary,
  },
  userListWrapper: {
    width: 300,
    padding: 0,
    boxShadow: "0 0 35px -22px #000",
  },
  userList: {
    padding: 0,
  },
  svg: {},
  svgDateLegend: {
    top: 0,
    position: ({ stick }: { stick: boolean }) => (stick ? "fixed" : "absolute"),
    background: theme.palette.background.paper,
    transition: "position .2s",
  },
  svgDateLegendFixed: {},
}));

export type HorizontalCalendarProps = {
  list: UserItem[];
  visibleDays?: number;
  start?: Moment;
  end?: Moment;
};

type UserItem = {
  name: string;
  image: string;
  progress?: number;
  absences: Absence[];
};

type Absence = {
  start: Moment;
  end: Moment;
};
