import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
  ThemeProvider as MuiProvider,
  createTheme,
  ThemeOptions,
} from "@material-ui/core";
import Theme from "./Theme";
import DarkTheme from "./DarkTheme";
import { useCookies } from "react-cookie";

export const ThemeContext = createContext<{
  dark: boolean;
  setDark: (value: boolean) => void;
}>({
  dark: true,
  setDark: () => {},
});

const THEME_DARK_MODE = "THEME_DARK_MODE";
export default function ThemeProvider({ children }: { children: ReactNode }) {
  const [cookies, setCookie] = useCookies([THEME_DARK_MODE]);

  // "matchMedia" in window
  // ? window.matchMedia("(prefers-color-scheme: dark)").matches

  const dark = Boolean(cookies[THEME_DARK_MODE] === "true");

  const [theme, setTheme] = useState<ThemeOptions>(dark ? DarkTheme : Theme);

  const setDark = (value: boolean) =>
    setCookie(THEME_DARK_MODE, value ? "true" : "");

  useEffect(() => setTheme(dark ? DarkTheme : Theme), [dark]);

  return (
    <ThemeContext.Provider value={{ dark, setDark }}>
      <MuiProvider theme={createTheme(theme)}>{children}</MuiProvider>
    </ThemeContext.Provider>
  );
}
