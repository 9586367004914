import React, { ForwardedRef, forwardRef } from 'react'
import { default as MuiGrid, GridProps as MuiGridProps } from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

export default forwardRef(function Grid<C extends React.ElementType = 'div'>(
  { className, item, grow = false, ...props }: GridProps<C>,
  ref: ForwardedRef<React.ComponentPropsWithRef<C>>,
): JSX.Element {
  const classes = useStyles()
  return (
    <MuiGrid
      item={item}
      className={classNames(className, {
        [classes.flexGrow]: item && grow,
      })}
      {...props}
      ref={ref}
    />
  )
}) as <C extends React.ElementType = 'div'>(
  props: GridProps<C>,
  ref: ForwardedRef<React.ComponentPropsWithRef<C>>,
) => JSX.Element

export type GridProps<C extends React.ElementType = 'div'> = MuiGridProps<
  C,
  {
    component?: C
    /**
     * If true the style flex-grow property will be set to 1.
     *
     */
    grow?: boolean
  }
>

const useStyles = makeStyles({
  flexGrow: {
    flexGrow: 1,
  },
})
