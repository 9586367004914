import moment from "moment";
import React from "react";
import Moment from "react-moment";

export default function Time({
  seconds,
  style = "positional",
  isRunning = false,
  interval = isRunning ? 500 : 0,
}: TimeProps) {
  switch (style) {
    default:
      return (
        <Moment
          durationFromNow
          ago
          interval={interval}
          format="HH:mm:ss"
          trim={false}
        >
          {moment().subtract(seconds, "second")}
        </Moment>
      );
  }
}

export type TimeProps = {
  seconds: number;
  style?: "positional" | "abbrevated" | "full";
  interval?: number;
  isRunning?: boolean;
};
