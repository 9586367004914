import Localization from "./Localization";

const localization = new Localization();
export default localization;

/**
 * You can use it with a string or with a template tag.
 *
 * @example localize('hello') or localize`hello`
 * @param {TemplateStringsArray | string[] | string} strings
 * @param {string[]} variables
 */
export const localize = localization.localize.bind(localization);

export const getLocale = localization.getLocale.bind(localization);
