import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import useDark from "../Theme/useDark";
import { localize } from "../../localization";
import {
  AssignmentInd,
  ChevronLeft,
  ChevronRight,
  VpnKeyRounded,
} from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import {
  loginMutation,
  LoginMutationInput,
  LoginMutationResult,
} from "../../gql/mutations/loginMutation";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import {
  registerMutation,
  RegistrationMutationInput,
  RegistrationMutationResult,
} from "../../gql/mutations/registerMutation";

export default function SignIn({ setToken }: SignInProps) {
  const classes = useStyles();
  const [signIn, setSignIn] = useState(true);
  const [loginError, setLoginError] = useState<string>();
  const [registerError, setRegisterError] = useState<string>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [firstname, setFirstname] = useState("");
  const [company, setCompany] = useState("");
  const [lastname, setLastname] = useState("");
  const [dark] = useDark();

  const [login, { data, loading: loginLoading, error }] = useMutation<
    LoginMutationResult,
    LoginMutationInput
  >(loginMutation);

  const [register, { data: registerData, loading: registerLoading }] =
    useMutation<RegistrationMutationResult, RegistrationMutationInput>(
      registerMutation
    );

  const loading = loginLoading || registerLoading;

  useEffect(() => {
    setLoginError(undefined);
    if (data?.login.status === "FAILURE") {
      setLoginError(localize`Login unsuccessful`);
    } else if (data?.login?.jwtToken) {
      setToken(data.login.jwtToken);
    }
  }, [data, setToken]);

  useEffect(() => {
    setRegisterError(undefined);
    if (registerData?.register?.status === "FAILURE") {
      try {
        const errors = JSON.parse(
          registerData?.register?.message || "{}"
        ) as Array<{ message: string }>;

        if (Object.values(errors).length > 0) {
          setRegisterError(
            Object.values(errors)
              .map(({ message }) => localize(message))
              .join("<br/>")
          );
        }
      } catch {
        setRegisterError(localize(registerData?.register?.message || "error"));
      }
    } else if (registerData?.register.status === "SUCCESS") {
      login({ variables: { email, password } });
    }
  }, [registerData, email, password, login]);

  const handleLogin = async () => {
    try {
      await login({
        variables: {
          email,
          password,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegister = async () => {
    try {
      await register({
        variables: {
          email,
          firstname,
          lastname,
          company,
          password,
          passwordRepeat,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
      spacing={3}
    >
      <Grid
        item
        className={classNames(classes.transition, {
          [classes.downscaleSignIn]: signIn === false,
        })}
      >
        <Paper elevation={7} className={classes.paper}>
          <Box padding={1}>
            <Grid container direction="column" spacing={1}>
              <Grid item className={classes.logo}>
                <img
                  src={`/logo${dark ? "_dark" : ""}.png`}
                  width="230"
                  alt="TimeTie"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="email"
                  value={email}
                  disabled={loading || !signIn}
                  onChange={e => setEmail(e.target.value)}
                  onKeyUp={e => (e.key === "Enter" ? handleLogin() : null)}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={localize`email`}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="password"
                  value={password}
                  disabled={loading}
                  onChange={e => setPassword(e.target.value)}
                  onKeyUp={e => (e.key === "Enter" ? handleLogin() : null)}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={localize`password`}
                  type="password"
                />
              </Grid>
              {error && (
                <Grid item>
                  <Alert severity="error">{error.message}</Alert>
                </Grid>
              )}
              {loginError && (
                <Grid item>
                  <Alert severity="error">{loginError}</Alert>
                </Grid>
              )}
              <Grid item>
                <Button
                  startIcon={<VpnKeyRounded />}
                  color="primary"
                  fullWidth
                  disabled={loading || !signIn}
                  variant="contained"
                  onClick={handleLogin}
                >{localize`login`}</Button>
                <br />
                <Button
                  color="primary"
                  fullWidth
                  disabled={loading || !signIn}
                >{localize`Forgot password`}</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <br />
        <Button
          endIcon={<ChevronRight />}
          fullWidth
          onClick={() => setSignIn(false)}
        >{localize`Register now`}</Button>
      </Grid>
      <Grid
        item
        className={classNames(classes.transition, {
          [classes.downscaleRegister]: signIn,
        })}
      >
        <Paper
          elevation={7}
          className={classNames(classes.paper, classes.paperRegister)}
        >
          <Box padding={1}>
            <Grid container direction="column" spacing={1}>
              <Grid item className={classes.logo}>
                <img
                  src={`/logo${dark ? "_dark" : ""}.png`}
                  width="230"
                  alt="TimeTie"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  value={company}
                  name="company"
                  disabled={loading || signIn}
                  onChange={e => setCompany(e.target.value)}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={localize`company`}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      value={firstname}
                      name="firstname"
                      disabled={loading || signIn}
                      onChange={e => setFirstname(e.target.value)}
                      size="small"
                      margin="none"
                      variant="outlined"
                      label={localize`firstname`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      value={lastname}
                      name="lastname"
                      disabled={loading || signIn}
                      onChange={e => setLastname(e.target.value)}
                      size="small"
                      margin="none"
                      variant="outlined"
                      label={localize`lastname`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  value={email}
                  name="email"
                  disabled={loading || signIn}
                  onChange={e => setEmail(e.target.value)}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={localize`email`}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="password"
                  value={password}
                  disabled={loading || signIn}
                  onChange={e => setPassword(e.target.value)}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={localize`password`}
                  type="password"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="passwordRepeat"
                  value={passwordRepeat}
                  disabled={loading || signIn}
                  onChange={e => setPasswordRepeat(e.target.value)}
                  onKeyUp={e => (e.key === "Enter" ? handleRegister() : null)}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={localize`password`}
                  type="password"
                />
              </Grid>
              {error && (
                <Grid item>
                  <Alert severity="error">{error.message}</Alert>
                </Grid>
              )}
              {registerError && (
                <Grid item>
                  <Alert severity="error">
                    <div dangerouslySetInnerHTML={{ __html: registerError }} />
                  </Alert>
                </Grid>
              )}
              <Grid item>
                <Button
                  startIcon={<AssignmentInd />}
                  color="secondary"
                  fullWidth
                  disabled={loading || signIn}
                  variant="contained"
                  onClick={handleRegister}
                >{localize`Register`}</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <br />
        <Button
          fullWidth
          startIcon={<ChevronLeft />}
          onClick={() => setSignIn(true)}
        >{localize`Log in`}</Button>
      </Grid>
    </Grid>
  );
}

type SignInProps = {
  setToken: (token: string | undefined) => void;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundImage: "linear-gradient(to top, #96fbc4 0%, #ffd7af 100%)",
    margin: 0,
    overflowY: "auto",
  },
  logo: {
    margin: "0 auto",
    display: "inline",
  },
  paper: {
    width: 350,
    maxWidth: "90vw",
    zIndex: 1,
    position: "relative",
  },
  paperRegister: {
    width: 400,
  },
  transition: {
    transition: "transform .5s ease-in-out",
  },
  downscaleRegister: {
    transform: "scale(0) translateX(100%)",
    zIndex: 0,
    opacity: 0.5,
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
  downscaleSignIn: {
    transform: "scale(0) translateX(-100%)",
    zIndex: 0,
    opacity: 0.5,
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
}));
