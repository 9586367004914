import translations from "./localization.json";

type LocalizationMap = {
  [key: string]: { [key: string]: string };
};

type LocalizationTranslations = {
  [key: string]: string;
};

class Localization {
  locale: string = "en";
  translationsByLocale: LocalizationMap;
  translations: LocalizationTranslations = {};

  constructor() {
    this.translationsByLocale = translations;
    this.setLocale(Localization.defaultLocale());
  }

  getLocale() {
    return this.locale;
  }

  setLocale(locale: string) {
    this.locale = locale;
    this.updateTranslations();
  }

  updateTranslations() {
    this.translations = Object.entries(
      this.translationsByLocale[this.locale] || {}
    ).reduce(
      (result, [key, value]) => ({
        ...result,
        [key.toLocaleLowerCase()]: value,
      }),
      {}
    );
  }

  /**
   *
   * @param {string} locale abbrevation for the language in iso format de,dk,ch,no
   * @param {object} translationObject {"english":"translation"}
   */
  addTranslations(locale: string, translationObject: LocalizationTranslations) {
    this.translationsByLocale[locale] = {
      ...(this.translationsByLocale[locale] || {}),
      ...translationObject,
    };

    this.updateTranslations();
  }

  /**
   * You can use it with a string or with a template tag.
   *
   * @example localize('hello') or localize`hello`
   * @param {TemplateStringsArray | string[] | string} strings
   * @param {string[]} variables
   */
  localize(
    strings: TemplateStringsArray | string[] | string,
    ...variables: string[]
  ) {
    let stringInput: string[] =
      typeof strings === "string" ? [strings] : Array.from(strings);

    const templateKey = stringInput.reduce((result, string, i) => {
      let suffix = i + 1 < stringInput.length && `$${i}`;

      if (suffix === false) {
        suffix = "";
      }
      return result + string + suffix;
    }, "");

    const theTranslation = this.translations[templateKey.toLowerCase()];
    let text = theTranslation || templateKey;

    const isDevelopment = process.env.NODE_ENV !== "production";

    if (
      isDevelopment &&
      this.locale !== "en" &&
      theTranslation === undefined &&
      typeof window !== "undefined"
    ) {
      window.missingTranslations = [
        ...(window.missingTranslations || []),
        templateKey,
      ].filter((v, i, a) => a.indexOf(v) === i);
      // console.warn(`Missing translation for ${this.locale}: ${templateKey}`);
    }
    if (variables.length > 0) {
      variables.forEach((value, i) => {
        text = text.replace(`$${i}`, value);
      });
    }

    return text;
  }

  static defaultLocale(): string {
    if (typeof window === "undefined") return "en";
    const navigator: Navigator = window.navigator;

    return (
      (navigator && navigator.language) ||
      (navigator && (navigator["userLanguage"] as string)) ||
      "en"
    )
      .split("-")
      .shift() as string;
  }
}

interface Navigator {
  userLanguage?: string;
  language: string;
}

declare global {
  interface Window {
    missingTranslations: string[];
    getMissingTranslationJson: () => void;
  }
}

window.getMissingTranslationJson = () => {
  console.log(
    window.missingTranslations.reduce<string>(
      (result, key) => result + `"${key}" : "",\n`,
      ""
    )
  );
};

export default Localization;
